export type SEOInfo = {
  SEOTitle: string;
  Strapline: string;
  additionalMessage: {
    title: string;
    links: {
      external: boolean;
      name: string;
      url: string;
      icon?: string;
    }[];
  };
};

export const SEOContent: { [key: string]: SEOInfo } = {
  Buy: {
    SEOTitle: 'Houses and Apartments for Sale on Daft.ie',
    Strapline: `Find your next home on Ireland's favourite property site`,
    additionalMessage: {
      title: 'Download our App',
      links: [
        {
          external: true,
          name: 'iPhone & iPad',
          url: 'https://apps.apple.com/ie/app/daft-ie/id346547065',
          icon: 'APPLE',
        },
        {
          external: true,
          name: 'Android',
          url: 'https://play.google.com/store/apps/details?id=com.daft.ie',
          icon: 'ANDROID',
        },
      ],
    },
  },
  Rent: {
    SEOTitle: 'Houses and Apartments for Rent on Daft.ie',
    Strapline: `View Houses and Apartments to rent in Ireland on Daft.ie, the Largest Property Listings Website in Ireland`,
    additionalMessage: {
      title: 'Looking for student accommodation?',
      links: [
        {
          external: false,
          name: 'Check out our dedicated section',
          url: '/student-accommodation',
        },
      ],
    },
  },
  Share: {
    SEOTitle: 'Rooms to Rent, House shares and Flat shares on Daft.ie',
    Strapline: `Find your next room to rent and roommates with Ireland's Largest Property Listings Website`,
    additionalMessage: {
      title: 'Looking for student accommodation?',
      links: [
        {
          external: false,
          name: 'Check out our dedicated section',
          url: '/student-accommodation',
        },
      ],
    },
  },
  Sold: {
    SEOTitle: 'Sold Properties in Ireland on Daft.ie',
    Strapline: `View sold properties in Ireland on Daft.ie, the Largest Property Listings Website in Ireland`,
    additionalMessage: {
      title: 'Download our App',
      links: [
        {
          external: true,
          name: 'iPhone & iPad',
          url: 'https://apps.apple.com/ie/app/daft-ie/id346547065',
          icon: 'APPLE',
        },
        {
          external: true,
          name: 'Android',
          url: 'https://play.google.com/store/apps/details?id=com.daft.ie',
          icon: 'ANDROID',
        },
      ],
    },
  },
  'New Homes': {
    SEOTitle: 'New Homes for Sale in Ireland',
    Strapline: `Ireland's largest selection of New Homes for Sale, Developments and New Builds`,
    additionalMessage: {
      title: 'Download our App',
      links: [
        {
          external: true,
          name: 'iPhone & iPad',
          url: 'https://apps.apple.com/ie/app/daft-ie/id346547065',
          icon: 'APPLE',
        },
        {
          external: true,
          name: 'Android',
          url: 'https://play.google.com/store/apps/details?id=com.daft.ie',
          icon: 'ANDROID',
        },
      ],
    },
  },
};
export type PopularAreasListItem = {
  title: string;
  url: {
    [section: string]: string;
  };
};

export type PopularAreasType = {
  title: string;
  content: {
    list: PopularAreasListItem[];
  };
};

export const PopularAreasList: PopularAreasType[] = [
  {
    title: 'District',
    content: {
      list: [
        {
          title: 'Cork City Centre',
          url: {
            Buy: '/property-for-sale/cork-city-centre-cork',
            Rent: '/property-for-rent/cork-city-centre-cork',
            Sold: '/sold-properties/cork-city-centre-cork',
            Share: '/sharing/cork-city-centre-cork',
            'New Homes': '/new-homes-for-sale/cork-city-centre-cork',
          },
        },
        {
          title: 'Cork City Suburbs',
          url: {
            Buy: '/property-for-sale/cork-city-suburbs-cork',
            Rent: '/property-for-rent/cork-city-suburbs-cork',
            Sold: '/sold-properties/cork-city-suburbs-cork',
            Share: '/sharing/cork-city-suburbs-cork',
            'New Homes': '/new-homes-for-sale/cork-city-suburbs-cork',
          },
        },
        {
          title: 'Cork Commuter Towns',
          url: {
            Buy: '/property-for-sale/cork-commuter-towns-cork',
            Rent: '/property-for-rent/cork-commuter-towns-cork',
            Sold: '/sold-properties/cork-commuter-towns-cork',
            Share: '/sharing/cork-commuter-towns-cork',
            'New Homes': '/new-homes-for-sale/cork-commuter-towns-cork',
          },
        },
        {
          title: 'Cork: West Cork',
          url: {
            Buy: '/property-for-sale/west-cork-cork',
            Rent: '/property-for-rent/west-cork-cork',
            Sold: '/sold-properties/west-cork-cork',
            Share: '/sharing/west-cork-cork',
            'New Homes': '/new-homes-for-sale/west-cork-cork',
          },
        },
        {
          title: 'Cork: East Cork',
          url: {
            Buy: '/property-for-sale/east-cork-cork',
            Rent: '/property-for-rent/east-cork-cork',
            Sold: '/sold-properties/east-cork-cork',
            Share: '/sharing/east-cork-cork',
            'New Homes': '/new-homes-for-sale/east-cork-cork',
          },
        },
        {
          title: 'Dublin City Centre',
          url: {
            Buy: '/property-for-sale/dublin-city-centre-dublin',
            Rent: '/property-for-rent/dublin-city-centre-dublin',
            Sold: '/sold-properties/dublin-city-centre-dublin',
            Share: '/sharing/dublin-city-centre-dublin',
            'New Homes': '/new-homes-for-sale/dublin-city-centre-dublin',
          },
        },
        {
          title: 'Dublin City, North',
          url: {
            Buy: '/property-for-sale/north-dublin-city-dublin',
            Rent: '/property-for-rent/north-dublin-city-dublin',
            Sold: '/sold-properties/north-dublin-city-dublin',
            Share: '/sharing/north-dublin-city-dublin',
            'New Homes': '/new-homes-for-sale/north-dublin-city-dublin',
          },
        },
        {
          title: 'Dublin City, South',
          url: {
            Buy: '/property-for-sale/south-dublin-city-dublin',
            Rent: '/property-for-rent/south-dublin-city-dublin',
            Sold: '/sold-properties/south-dublin-city-dublin',
            Share: '/sharing/south-dublin-city-dublin',
            'New Homes': '/new-homes-for-sale/south-dublin-city-dublin',
          },
        },
        {
          title: 'Dublin County, North',
          url: {
            Buy: '/property-for-sale/north-co-dublin-dublin',
            Rent: '/property-for-rent/north-co-dublin-dublin',
            Sold: '/sold-properties/north-co-dublin-dublin',
            Share: '/sharing/north-co-dublin-dublin',
            'New Homes': '/new-homes-for-sale/north-co-dublin-dublin',
          },
        },
        {
          title: 'Dublin County, South',
          url: {
            Buy: '/property-for-sale/south-co-dublin-dublin',
            Rent: '/property-for-rent/south-co-dublin-dublin',
            Sold: '/sold-properties/south-co-dublin-dublin',
            Share: '/sharing/south-co-dublin-dublin',
            'New Homes': '/new-homes-for-sale/south-co-dublin-dublin',
          },
        },
        {
          title: 'Dublin County, West',
          url: {
            Buy: '/property-for-sale/west-co-dublin-dublin',
            Rent: '/property-for-rent/west-co-dublin-dublin',
            Sold: '/sold-properties/west-co-dublin-dublin',
            Share: '/sharing/west-co-dublin-dublin',
            'New Homes': '/new-homes-for-sale/west-co-dublin-dublin',
          },
        },
        {
          title: 'Dublin Commuter Towns',
          url: {
            Buy: '/property-for-sale/dublin-commuter-towns-dublin',
            Rent: '/property-for-rent/dublin-commuter-towns-dublin',
            Sold: '/sold-properties/dublin-commuter-towns-dublin',
            Share: '/sharing/dublin-commuter-towns-dublin',
            'New Homes': '/new-homes-for-sale/dublin-commuter-towns-dublin',
          },
        },
        {
          title: 'Galway City Centre',
          url: {
            Buy: '/property-for-sale/galway-city-centre-galway',
            Rent: '/property-for-rent/galway-city-centre-galway',
            Sold: '/sold-properties/galway-city-centre-galway',
            Share: '/sharing/galway-city-centre-galway',
            'New Homes': '/new-homes-for-sale/galway-city-centre-galway',
          },
        },
        {
          title: 'Galway City Suburbs',
          url: {
            Buy: '/property-for-sale/galway-city-suburbs-galway',
            Rent: '/property-for-rent/galway-city-suburbs-galway',
            Sold: '/sold-properties/galway-city-suburbs-galway',
            Share: '/sharing/galway-city-suburbs-galway',
            'New Homes': '/new-homes-for-sale/galway-city-suburbs-galway',
          },
        },
        {
          title: 'Galway Commuter Towns',
          url: {
            Buy: '/property-for-sale/galway-commuter-towns-galway',
            Rent: '/property-for-rent/galway-commuter-towns-galway',
            Sold: '/sold-properties/galway-commuter-towns-galway',
            Share: '/sharing/galway-commuter-towns-galway',
            'New Homes': '/new-homes-for-sale/galway-commuter-towns-galway',
          },
        },
        {
          title: 'Galway: Connemara',
          url: {
            Buy: '/property-for-sale/connemara-galway',
            Rent: '/property-for-rent/connemara-galway',
            Sold: '/sold-properties/connemara-galway',
            Share: '/sharing/connemara-galway',
            'New Homes': '/new-homes-for-sale/connemara-galway',
          },
        },
        {
          title: 'Limerick City Centre',
          url: {
            Buy: '/property-for-sale/limerick-city-centre-limerick',
            Rent: '/property-for-rent/limerick-city-centre-limerick',
            Sold: '/sold-properties/limerick-city-centre-limerick',
            Share: '/sharing/limerick-city-centre-limerick',
            'New Homes': '/new-homes-for-sale/limerick-city-centre-limerick',
          },
        },
        {
          title: 'Limerick City Suburbs',
          url: {
            Buy: '/property-for-sale/limerick-city-suburbs-limerick',
            Rent: '/property-for-rent/limerick-city-suburbs-limerick',
            Sold: '/sold-properties/limerick-city-suburbs-limerick',
            Share: '/sharing/limerick-city-suburbs-limerick',
            'New Homes': '/new-homes-for-sale/limerick-city-suburbs-limerick',
          },
        },
        {
          title: 'Limerick Commuter Towns',
          url: {
            Buy: '/property-for-sale/limerick-commuter-towns-limerick',
            Rent: '/property-for-rent/limerick-commuter-towns-limerick',
            Sold: '/sold-properties/limerick-commuter-towns-limerick',
            Share: '/sharing/limerick-commuter-towns-limerick',
            'New Homes': '/new-homes-for-sale/limerick-commuter-towns-limerick',
          },
        },
        {
          title: 'Waterford City Centre',
          url: {
            Buy: '/property-for-sale/waterford-city-centre-waterford',
            Rent: '/property-for-rent/waterford-city-centre-waterford',
            Sold: '/sold-properties/waterford-city-centre-waterford',
            Share: '/sharing/waterford-city-centre-waterford',
            'New Homes': '/new-homes-for-sale/waterford-city-centre-waterford',
          },
        },
      ],
    },
  },

  {
    title: 'Dublin City',
    content: {
      list: [
        {
          title: 'Ballsbridge',
          url: {
            Buy: '/property-for-sale/ballsbridge-dublin',
            Rent: '/property-for-rent/ballsbridge-dublin',
            Sold: '/sold-properties/ballsbridge-dublin',
            Share: '/sharing/ballsbridge-dublin',
            'New Homes': '/new-homes-for-sale/ballsbridge-dublin',
          },
        },
        {
          title: 'Castleknock',
          url: {
            Buy: '/property-for-sale/castleknock-dublin',
            Rent: '/property-for-rent/castleknock-dublin',
            Sold: '/sold-properties/castleknock-dublin',
            Share: '/sharing/castleknock-dublin',
            'New Homes': '/new-homes-for-sale/castleknock-dublin',
          },
        },
        {
          title: 'Clontarf',
          url: {
            Buy: '/property-for-sale/clontarf-dublin',
            Rent: '/property-for-rent/clontarf-dublin',
            Sold: '/sold-properties/clontarf-dublin',
            Share: '/sharing/clontarf-dublin',
            'New Homes': '/new-homes-for-sale/clontarf-dublin',
          },
        },
        {
          title: 'Donnybrook',
          url: {
            Buy: '/property-for-sale/donnybrook-dublin',
            Rent: '/property-for-rent/donnybrook-dublin',
            Sold: '/sold-properties/donnybrook-dublin',
            Share: '/sharing/donnybrook-dublin',
            'New Homes': '/new-homes-for-sale/donnybrook-dublin',
          },
        },
        {
          title: 'Drumcondra',
          url: {
            Buy: '/property-for-sale/drumcondra-dublin',
            Rent: '/property-for-rent/drumcondra-dublin',
            Sold: '/sold-properties/drumcondra-dublin',
            Share: '/sharing/drumcondra-dublin',
            'New Homes': '/new-homes-for-sale/drumcondra-dublin',
          },
        },
        {
          title: 'Dublin 1',
          url: {
            Buy: '/property-for-sale/dublin-1-dublin',
            Rent: '/property-for-rent/dublin-1-dublin',
            Sold: '/sold-properties/dublin-1-dublin',
            Share: '/sharing/dublin-1-dublin',
            'New Homes': '/new-homes-for-sale/dublin-1-dublin',
          },
        },
        {
          title: 'Dublin 2',
          url: {
            Buy: '/property-for-sale/dublin-2-dublin',
            Rent: '/property-for-rent/dublin-2-dublin',
            Sold: '/sold-properties/dublin-2-dublin',
            Share: '/sharing/dublin-2-dublin',
            'New Homes': '/new-homes-for-sale/dublin-2-dublin',
          },
        },
        {
          title: 'Dublin 8',
          url: {
            Buy: '/property-for-sale/dublin-8-dublin',
            Rent: '/property-for-rent/dublin-8-dublin',
            Sold: '/sold-properties/dublin-8-dublin',
            Share: '/sharing/dublin-8-dublin',
            'New Homes': '/new-homes-for-sale/dublin-8-dublin',
          },
        },
        {
          title: 'Dundrum',
          url: {
            Buy: '/property-for-sale/dundrum-dublin',
            Rent: '/property-for-rent/dundrum-dublin',
            Sold: '/sold-properties/dundrum-dublin',
            Share: '/sharing/dundrum-dublin',
            'New Homes': '/new-homes-for-sale/dundrum-dublin',
          },
        },
        {
          title: 'Finglas',
          url: {
            Buy: '/property-for-sale/finglas-dublin',
            Rent: '/property-for-rent/finglas-dublin',
            Sold: '/sold-properties/finglas-dublin',
            Share: '/sharing/finglas-dublin',
            'New Homes': '/new-homes-for-sale/finglas-dublin',
          },
        },
        {
          title: 'Glasnevin',
          url: {
            Buy: '/property-for-sale/glasnevin-dublin',
            Rent: '/property-for-rent/glasnevin-dublin',
            Sold: '/sold-properties/glasnevin-dublin',
            Share: '/sharing/glasnevin-dublin',
            'New Homes': '/new-homes-for-sale/glasnevin-dublin',
          },
        },
        {
          title: 'Inchicore',
          url: {
            Buy: '/property-for-sale/inchicore-dublin',
            Rent: '/property-for-rent/inchicore-dublin',
            Sold: '/sold-properties/inchicore-dublin',
            Share: '/sharing/inchicore-dublin',
            'New Homes': '/new-homes-for-sale/inchicore-dublin',
          },
        },
        {
          title: 'Kilmainham',
          url: {
            Buy: '/property-for-sale/kilmainham-dublin',
            Rent: '/property-for-rent/kilmainham-dublin',
            Sold: '/sold-properties/kilmainham-dublin',
            Share: '/sharing/kilmainham-dublin',
            'New Homes': '/new-homes-for-sale/kilmainham-dublin',
          },
        },
        {
          title: 'Phibsborough',
          url: {
            Buy: '/property-for-sale/phibsborough-dublin',
            Rent: '/property-for-rent/phibsborough-dublin',
            Sold: '/sold-properties/phibsborough-dublin',
            Share: '/sharing/phibsborough-dublin',
            'New Homes': '/new-homes-for-sale/phibsborough-dublin',
          },
        },
        {
          title: 'Ranelagh',
          url: {
            Buy: '/property-for-sale/ranelagh-dublin',
            Rent: '/property-for-rent/ranelagh-dublin',
            Sold: '/sold-properties/ranelagh-dublin',
            Share: '/sharing/ranelagh-dublin',
            'New Homes': '/new-homes-for-sale/ranelagh-dublin',
          },
        },
        {
          title: 'Rathfarnham',
          url: {
            Buy: '/property-for-sale/rathfarnham-dublin',
            Rent: '/property-for-rent/rathfarnham-dublin',
            Sold: '/sold-properties/rathfarnham-dublin',
            Share: '/sharing/rathfarnham-dublin',
            'New Homes': '/new-homes-for-sale/rathfarnham-dublin',
          },
        },
        {
          title: 'Rathmines',
          url: {
            Buy: '/property-for-sale/rathmines-dublin',
            Rent: '/property-for-rent/rathmines-dublin',
            Sold: '/sold-properties/rathmines-dublin',
            Share: '/sharing/rathmines-dublin',
            'New Homes': '/new-homes-for-sale/rathmines-dublin',
          },
        },
        {
          title: 'Sandyford',
          url: {
            Buy: '/property-for-sale/sandyford-dublin',
            Rent: '/property-for-rent/sandyford-dublin',
            Sold: '/sold-properties/sandyford-dublin',
            Share: '/sharing/sandyford-dublin',
            'New Homes': '/new-homes-for-sale/sandyford-dublin',
          },
        },
        {
          title: 'Sandymount',
          url: {
            Buy: '/property-for-sale/sandymount-dublin',
            Rent: '/property-for-rent/sandymount-dublin',
            Sold: '/sold-properties/sandymount-dublin',
            Share: '/sharing/sandymount-dublin',
            'New Homes': '/new-homes-for-sale/sandymount-dublin',
          },
        },
        {
          title: 'Smithfield',
          url: {
            Buy: '/property-for-sale/smithfield-dublin',
            Rent: '/property-for-rent/smithfield-dublin',
            Sold: '/sold-properties/smithfield-dublin',
            Share: '/sharing/smithfield-dublin',
            'New Homes': '/new-homes-for-sale/smithfield-dublin',
          },
        },
      ],
    },
  },
  {
    title: 'Dublin County',
    content: {
      list: [
        {
          title: 'Balbriggan',
          url: {
            Buy: '/property-for-sale/balbriggan-dublin',
            Rent: '/property-for-rent/balbriggan-dublin',
            Sold: '/sold-properties/balbriggan-dublin',
            Share: '/sharing/balbriggan-dublin',
            'New Homes': '/new-homes-for-sale/balbriggan-dublin',
          },
        },
        {
          title: 'Blackrock',
          url: {
            Buy: '/property-for-sale/blackrock-dublin',
            Rent: '/property-for-rent/blackrock-dublin',
            Sold: '/sold-properties/blackrock-dublin',
            Share: '/sharing/blackrock-dublin',
            'New Homes': '/new-homes-for-sale/blackrock-dublin',
          },
        },
        {
          title: 'Blanchardstown',
          url: {
            Buy: '/property-for-sale/blanchardstown-dublin',
            Rent: '/property-for-rent/blanchardstown-dublin',
            Sold: '/sold-properties/blanchardstown-dublin',
            Share: '/sharing/blanchardstown-dublin',
            'New Homes': '/new-homes-for-sale/blanchardstown-dublin',
          },
        },
        {
          title: 'Clondalkin',
          url: {
            Buy: '/property-for-sale/clondalkin-dublin',
            Rent: '/property-for-rent/clondalkin-dublin',
            Sold: '/sold-properties/clondalkin-dublin',
            Share: '/sharing/clondalkin-dublin',
            'New Homes': '/new-homes-for-sale/clondalkin-dublin',
          },
        },
        {
          title: 'Clonee',
          url: {
            Buy: '/property-for-sale/clonee-dublin',
            Rent: '/property-for-rent/clonee-dublin',
            Sold: '/sold-properties/clonee-dublin',
            Share: '/sharing/clonee-dublin',
            'New Homes': '/new-homes-for-sale/clonee-dublin',
          },
        },
        {
          title: 'Clonsilla',
          url: {
            Buy: '/property-for-sale/clonsilla-dublin',
            Rent: '/property-for-rent/clonsilla-dublin',
            Sold: '/sold-properties/clonsilla-dublin',
            Share: '/sharing/clonsilla-dublin',
            'New Homes': '/new-homes-for-sale/clonsilla-dublin',
          },
        },
        {
          title: 'Dalkey',
          url: {
            Buy: '/property-for-sale/dalkey-dublin',
            Rent: '/property-for-rent/dalkey-dublin',
            Sold: '/sold-properties/dalkey-dublin',
            Share: '/sharing/dalkey-dublin',
            'New Homes': '/new-homes-for-sale/dalkey-dublin',
          },
        },
        {
          title: 'Donabate',
          url: {
            Buy: '/property-for-sale/donabate-dublin',
            Rent: '/property-for-rent/donabate-dublin',
            Sold: '/sold-properties/donabate-dublin',
            Share: '/sharing/donabate-dublin',
            'New Homes': '/new-homes-for-sale/donabate-dublin',
          },
        },
        {
          title: 'Dun Laoghaire',
          url: {
            Buy: '/property-for-sale/dun-laoghaire-dublin',
            Rent: '/property-for-rent/dun-laoghaire-dublin',
            Sold: '/sold-properties/dun-laoghaire-dublin',
            Share: '/sharing/dun-laoghaire-dublin',
            'New Homes': '/new-homes-for-sale/dun-laoghaire-dublin',
          },
        },
        {
          title: 'Howth',
          url: {
            Buy: '/property-for-sale/howth-dublin',
            Rent: '/property-for-rent/howth-dublin',
            Sold: '/sold-properties/howth-dublin',
            Share: '/sharing/howth-dublin',
            'New Homes': '/new-homes-for-sale/howth-dublin',
          },
        },
        {
          title: 'Killiney',
          url: {
            Buy: '/property-for-sale/killiney-dublin',
            Rent: '/property-for-rent/killiney-dublin',
            Sold: '/sold-properties/killiney-dublin',
            Share: '/sharing/killiney-dublin',
            'New Homes': '/new-homes-for-sale/killiney-dublin',
          },
        },
        {
          title: 'Lucan',
          url: {
            Buy: '/property-for-sale/lucan-dublin',
            Rent: '/property-for-rent/lucan-dublin',
            Sold: '/sold-properties/lucan-dublin',
            Share: '/sharing/lucan-dublin',
            'New Homes': '/new-homes-for-sale/lucan-dublin',
          },
        },
        {
          title: 'Malahide',
          url: {
            Buy: '/property-for-sale/malahide-dublin',
            Rent: '/property-for-rent/malahide-dublin',
            Sold: '/sold-properties/malahide-dublin',
            Share: '/sharing/malahide-dublin',
            'New Homes': '/new-homes-for-sale/malahide-dublin',
          },
        },
        {
          title: 'Monkstown',
          url: {
            Buy: '/property-for-sale/monkstown-dublin',
            Rent: '/property-for-rent/monkstown-dublin',
            Sold: '/sold-properties/monkstown-dublin',
            Share: '/sharing/monkstown-dublin',
            'New Homes': '/new-homes-for-sale/monkstown-dublin',
          },
        },
        {
          title: 'Raheny',
          url: {
            Buy: '/property-for-sale/raheny-dublin',
            Rent: '/property-for-rent/raheny-dublin',
            Sold: '/sold-properties/raheny-dublin',
            Share: '/sharing/raheny-dublin',
            'New Homes': '/new-homes-for-sale/raheny-dublin',
          },
        },
        {
          title: 'Rush',
          url: {
            Buy: '/property-for-sale/rush-dublin',
            Rent: '/property-for-rent/rush-dublin',
            Sold: '/sold-properties/rush-dublin',
            Share: '/sharing/rush-dublin',
            'New Homes': '/new-homes-for-sale/rush-dublin',
          },
        },
        {
          title: 'Skerries',
          url: {
            Buy: '/property-for-sale/skerries-dublin',
            Rent: '/property-for-rent/skerries-dublin',
            Sold: '/sold-properties/skerries-dublin',
            Share: '/sharing/skerries-dublin',
            'New Homes': '/new-homes-for-sale/skerries-dublin',
          },
        },
        {
          title: 'Stepaside',
          url: {
            Buy: '/property-for-sale/stepaside-dublin',
            Rent: '/property-for-rent/stepaside-dublin',
            Sold: '/sold-properties/stepaside-dublin',
            Share: '/sharing/stepaside-dublin',
            'New Homes': '/new-homes-for-sale/stepaside-dublin',
          },
        },
        {
          title: 'Swords',
          url: {
            Buy: '/property-for-sale/swords-dublin',
            Rent: '/property-for-rent/swords-dublin',
            Sold: '/sold-properties/swords-dublin',
            Share: '/sharing/swords-dublin',
            'New Homes': '/new-homes-for-sale/swords-dublin',
          },
        },
        {
          title: 'Tallaght',
          url: {
            Buy: '/property-for-sale/tallaght-dublin',
            Rent: '/property-for-rent/tallaght-dublin',
            Sold: '/sold-properties/tallaght-dublin',
            Share: '/sharing/tallaght-dublin',
            'New Homes': '/new-homes-for-sale/tallaght-dublin',
          },
        },
      ],
    },
  },
  {
    title: 'Towns & Cities',
    content: {
      list: [
        {
          title: 'Blackrock, Cork',
          url: {
            Buy: '/property-for-sale/blackrock-cork',
            Rent: '/property-for-rent/blackrock-cork',
            Sold: '/sold-properties/blackrock-cork',
            Share: '/sharing/blackrock-cork',
            'New Homes': '/new-homes-for-sale/blackrock-cork',
          },
        },
        {
          title: 'Bray',
          url: {
            Buy: '/property-for-sale/bray-wicklow',
            Rent: '/property-for-rent/bray-wicklow',
            Sold: '/sold-properties/bray-wicklow',
            Share: '/sharing/bray-wicklow',
            'New Homes': '/new-homes-for-sale/bray-wicklow',
          },
        },
        {
          title: 'Cork City',
          url: {
            Buy: '/property-for-sale/cork-city',
            Rent: '/property-for-rent/cork-city',
            Sold: '/sold-properties/cork-city',
            Share: '/sharing/cork-city',
            'New Homes': '/new-homes-for-sale/cork-city',
          },
        },
        {
          title: 'Douglas',
          url: {
            Buy: '/property-for-sale/douglas-cork',
            Rent: '/property-for-rent/douglas-cork',
            Sold: '/sold-properties/douglas-cork',
            Share: '/sharing/douglas-cork',
            'New Homes': '/new-homes-for-sale/douglas-cork',
          },
        },
        {
          title: 'Drogheda',
          url: {
            Buy: '/property-for-sale/drogheda-louth',
            Rent: '/property-for-rent/drogheda-louth',
            Sold: '/sold-properties/drogheda-louth',
            Share: '/sharing/drogheda-louth',
            'New Homes': '/new-homes-for-sale/drogheda-louth',
          },
        },
        {
          title: 'Dublin City',
          url: {
            Buy: '/property-for-sale/dublin-city',
            Rent: '/property-for-rent/dublin-city',
            Sold: '/sold-properties/dublin-city',
            Share: '/sharing/dublin-city',
            'New Homes': '/new-homes-for-sale/dublin-city',
          },
        },
        {
          title: 'Dundalk',
          url: {
            Buy: '/property-for-sale/dundalk-louth',
            Rent: '/property-for-rent/dundalk-louth',
            Sold: '/sold-properties/dundalk-louth',
            Share: '/sharing/dundalk-louth',
            'New Homes': '/new-homes-for-sale/dundalk-louth',
          },
        },
        {
          title: 'Ennis',
          url: {
            Buy: '/property-for-sale/ennis-clare',
            Rent: '/property-for-rent/ennis-clare',
            Sold: '/sold-properties/ennis-clare',
            Share: '/sharing/ennis-clare',
            'New Homes': '/new-homes-for-sale/ennis-clare',
          },
        },
        {
          title: 'Galway City',
          url: {
            Buy: '/property-for-sale/galway-city',
            Rent: '/property-for-rent/galway-city',
            Sold: '/sold-properties/galway-city',
            Share: '/sharing/galway-city',
            'New Homes': '/new-homes-for-sale/galway-city',
          },
        },
        {
          title: 'Gorey',
          url: {
            Buy: '/property-for-sale/gorey-wexford',
            Rent: '/property-for-rent/gorey-wexford',
            Sold: '/sold-properties/gorey-wexford',
            Share: '/sharing/gorey-wexford',
            'New Homes': '/new-homes-for-sale/gorey-wexford',
          },
        },
        {
          title: 'Kilkenny',
          url: {
            Buy: '/property-for-sale/kilkenny-kilkenny',
            Rent: '/property-for-rent/kilkenny-kilkenny',
            Sold: '/sold-properties/kilkenny-kilkenny',
            Share: '/sharing/kilkenny-kilkenny',
            'New Homes': '/new-homes-for-sale/kilkenny-kilkenny',
          },
        },
        {
          title: 'Killarney',
          url: {
            Buy: '/property-for-sale/killarney-kerry',
            Rent: '/property-for-rent/killarney-kerry',
            Sold: '/sold-properties/killarney-kerry',
            Share: '/sharing/killarney-kerry',
            'New Homes': '/new-homes-for-sale/killarney-kerry',
          },
        },
        {
          title: 'Limerick City',
          url: {
            Buy: '/property-for-sale/limerick-city',
            Rent: '/property-for-rent/limerick-city',
            Sold: '/sold-properties/limerick-city',
            Share: '/sharing/limerick-city',
            'New Homes': '/new-homes-for-sale/limerick-city',
          },
        },
        {
          title: 'Mallow',
          url: {
            Buy: '/property-for-sale/mallow-cork',
            Rent: '/property-for-rent/mallow-cork',
            Sold: '/sold-properties/mallow-cork',
            Share: '/sharing/mallow-cork',
            'New Homes': '/new-homes-for-sale/mallow-cork',
          },
        },
        {
          title: 'Millingar',
          url: {
            Buy: '/property-for-sale/mullingar-westmeath',
            Rent: '/property-for-rent/mullingar-westmeath',
            Sold: '/sold-properties/mullingar-westmeath',
            Share: '/sharing/mullingar-westmeath',
            'New Homes': '/new-homes-for-sale/mullingar-westmeath',
          },
        },
        {
          title: 'Naas',
          url: {
            Buy: '/property-for-sale/naas-kildare',
            Rent: '/property-for-rent/naas-kildare',
            Sold: '/sold-properties/naas-kildare',
            Share: '/sharing/naas-kildare',
            'New Homes': '/new-homes-for-sale/naas-kildare',
          },
        },
        {
          title: 'Navan',
          url: {
            Buy: '/property-for-sale/navan-meath',
            Rent: '/property-for-rent/navan-meath',
            Sold: '/sold-properties/navan-meath',
            Share: '/sharing/navan-meath',
            'New Homes': '/new-homes-for-sale/navan-meath',
          },
        },
        {
          title: 'Newbridge',
          url: {
            Buy: '/property-for-sale/newbridge-kildare',
            Rent: '/property-for-rent/newbridge-kildare',
            Sold: '/sold-properties/newbridge-kildare',
            Share: '/sharing/newbridge-kildare',
            'New Homes': '/new-homes-for-sale/newbridge-kildare',
          },
        },
        {
          title: 'Tralee',
          url: {
            Buy: '/property-for-sale/tralee-kerry',
            Rent: '/property-for-rent/tralee-kerry',
            Sold: '/sold-properties/tralee-kerry',
            Share: '/sharing/tralee-kerry',
            'New Homes': '/new-homes-for-sale/tralee-kerry',
          },
        },
        {
          title: 'Waterford City',
          url: {
            Buy: '/property-for-sale/waterford-city',
            Rent: '/property-for-rent/waterford-city',
            Sold: '/sold-properties/waterford-city',
            Share: '/sharing/waterford-city',
            'New Homes': '/new-homes-for-sale/waterford-city',
          },
        },
      ],
    },
  },
  {
    title: 'Counties',
    content: {
      list: [
        {
          title: 'Carlow',
          url: {
            Buy: '/property-for-sale/carlow',
            Rent: '/property-for-rent/carlow',
            Sold: '/sold-properties/carlow',
            Share: '/sharing/carlow',
            'New Homes': '/new-homes-for-sale/carlow',
          },
        },
        {
          title: 'Cavan',
          url: {
            Buy: '/property-for-sale/cavan',
            Rent: '/property-for-rent/cavan',
            Sold: '/sold-properties/cavan',
            Share: '/sharing/cavan',
            'New Homes': '/new-homes-for-sale/cavan',
          },
        },
        {
          title: 'Clare',
          url: {
            Buy: '/property-for-sale/clare',
            Rent: '/property-for-rent/clare',
            Sold: '/sold-properties/clare',
            Share: '/sharing/clare',
            'New Homes': '/new-homes-for-sale/clare',
          },
        },
        {
          title: 'Cork',
          url: {
            Buy: '/property-for-sale/cork',
            Rent: '/property-for-rent/cork',
            Sold: '/sold-properties/cork',
            Share: '/sharing/cork',
            'New Homes': '/new-homes-for-sale/cork',
          },
        },
        {
          title: 'Donegal',
          url: {
            Buy: '/property-for-sale/donegal',
            Rent: '/property-for-rent/donegal',
            Sold: '/sold-properties/donegal',
            Share: '/sharing/donegal',
            'New Homes': '/new-homes-for-sale/donegal',
          },
        },
        {
          title: 'Dublin',
          url: {
            Buy: '/property-for-sale/dublin',
            Rent: '/property-for-rent/dublin',
            Sold: '/sold-properties/dublin',
            Share: '/sharing/dublin',
            'New Homes': '/new-homes-for-sale/dublin',
          },
        },
        {
          title: 'Galway',
          url: {
            Buy: '/property-for-sale/galway',
            Rent: '/property-for-rent/galway',
            Sold: '/sold-properties/galway',
            Share: '/sharing/galway',
            'New Homes': '/new-homes-for-sale/galway',
          },
        },
        {
          title: 'Kerry',
          url: {
            Buy: '/property-for-sale/kerry',
            Rent: '/property-for-rent/kerry',
            Sold: '/sold-properties/kerry',
            Share: '/sharing/kerry',
            'New Homes': '/new-homes-for-sale/kerry',
          },
        },
        {
          title: 'Kildare',
          url: {
            Buy: '/property-for-sale/kildare',
            Rent: '/property-for-rent/kildare',
            Sold: '/sold-properties/kildare',
            Share: '/sharing/kildare',
            'New Homes': '/new-homes-for-sale/kildare',
          },
        },
        {
          title: 'Kilkenny',
          url: {
            Buy: '/property-for-sale/kilkenny',
            Rent: '/property-for-rent/kilkenny',
            Sold: '/sold-properties/kilkenny',
            Share: '/sharing/kilkenny',
            'New Homes': '/new-homes-for-sale/kilkenny',
          },
        },
        {
          title: 'Laois',
          url: {
            Buy: '/property-for-sale/laois',
            Rent: '/property-for-rent/laois',
            Sold: '/sold-properties/laois',
            Share: '/sharing/laois',
            'New Homes': '/new-homes-for-sale/laois',
          },
        },
        {
          title: 'Leitrim',
          url: {
            Buy: '/property-for-sale/leitrim',
            Rent: '/property-for-rent/leitrim',
            Sold: '/sold-properties/leitrim',
            Share: '/sharing/leitrim',
            'New Homes': '/new-homes-for-sale/leitrim',
          },
        },
        {
          title: 'Limerick',
          url: {
            Buy: '/property-for-sale/limerick',
            Rent: '/property-for-rent/limerick',
            Sold: '/sold-properties/limerick',
            Share: '/sharing/limerick',
            'New Homes': '/new-homes-for-sale/limerick',
          },
        },
        {
          title: 'Longford',
          url: {
            Buy: '/property-for-sale/longford',
            Rent: '/property-for-rent/longford',
            Sold: '/sold-properties/longford',
            Share: '/sharing/longford',
            'New Homes': '/new-homes-for-sale/longford',
          },
        },
        {
          title: 'Louth',
          url: {
            Buy: '/property-for-sale/louth',
            Rent: '/property-for-rent/louth',
            Sold: '/sold-properties/louth',
            Share: '/sharing/louth',
            'New Homes': '/new-homes-for-sale/louth',
          },
        },
        {
          title: 'Mayo',
          url: {
            Buy: '/property-for-sale/mayo',
            Rent: '/property-for-rent/mayo',
            Sold: '/sold-properties/mayo',
            Share: '/sharing/mayo',
            'New Homes': '/new-homes-for-sale/mayo',
          },
        },
        {
          title: 'Meath',
          url: {
            Buy: '/property-for-sale/meath',
            Rent: '/property-for-rent/meath',
            Sold: '/sold-properties/meath',
            Share: '/sharing/meath',
            'New Homes': '/new-homes-for-sale/meath',
          },
        },
        {
          title: 'Monaghan',
          url: {
            Buy: '/property-for-sale/monaghan',
            Rent: '/property-for-rent/monaghan',
            Sold: '/sold-properties/monaghan',
            Share: '/sharing/monaghan',
            'New Homes': '/new-homes-for-sale/monaghan',
          },
        },
        {
          title: 'Offaly',
          url: {
            Buy: '/property-for-sale/offaly',
            Rent: '/property-for-rent/offaly',
            Sold: '/sold-properties/offaly',
            Share: '/sharing/offaly',
            'New Homes': '/new-homes-for-sale/offaly',
          },
        },
        {
          title: 'Roscommon',
          url: {
            Buy: '/property-for-sale/roscommon',
            Rent: '/property-for-rent/roscommon',
            Sold: '/sold-properties/roscommon',
            Share: '/sharing/roscommon',
            'New Homes': '/new-homes-for-sale/roscommon',
          },
        },
        {
          title: 'Sligo',
          url: {
            Buy: '/property-for-sale/sligo',
            Rent: '/property-for-rent/sligo',
            Sold: '/sold-properties/sligo',
            Share: '/sharing/sligo',
            'New Homes': '/new-homes-for-sale/sligo',
          },
        },
        {
          title: 'Tipperary',
          url: {
            Buy: '/property-for-sale/tipperary',
            Rent: '/property-for-rent/tipperary',
            Sold: '/sold-properties/tipperary',
            Share: '/sharing/tipperary',
            'New Homes': '/new-homes-for-sale/tipperary',
          },
        },
        {
          title: 'Waterford',
          url: {
            Buy: '/property-for-sale/waterford',
            Rent: '/property-for-rent/waterford',
            Sold: '/sold-properties/waterford',
            Share: '/sharing/waterford',
            'New Homes': '/new-homes-for-sale/waterford',
          },
        },
        {
          title: 'Westmeath',
          url: {
            Buy: '/property-for-sale/westmeath',
            Rent: '/property-for-rent/westmeath',
            Sold: '/sold-properties/westmeath',
            Share: '/sharing/westmeath',
            'New Homes': '/new-homes-for-sale/westmeath',
          },
        },
        {
          title: 'Wexford',
          url: {
            Buy: '/property-for-sale/wexford',
            Rent: '/property-for-rent/wexford',
            Sold: '/sold-properties/wexford',
            Share: '/sharing/wexford',
            'New Homes': '/new-homes-for-sale/wexford',
          },
        },
        {
          title: 'Wicklow',
          url: {
            Buy: '/property-for-sale/wicklow',
            Rent: '/property-for-rent/wicklow',
            Sold: '/sold-properties/wicklow',
            Share: '/sharing/wicklow',
            'New Homes': '/new-homes-for-sale/wicklow',
          },
        },
      ],
    },
  },
];
