import { TabsPanelsType } from '../../../components/Toolkit/TabsPanels/TabsPanels.types';
import { CDN_URL_STATIC_DIRECTORY, BASE_URL } from '../../../helpers/utils';

export const PropertyToolsConfig: TabsPanelsType[] = [
  {
    section: 'Selling',
    content: {
      list: [
        {
          id: 'homepage-property-tools-how-to-sell',
          cardTitle: 'How to sell my home',
          cardText: 'Learn more about the steps involved in selling',
          url: 'https://www.sell.daft.ie/?utm_source=Home+Page&utm_medium=Property+Tools+Selling+Tab&utm_campaign=How+to+sell+my+home',
          image: `${CDN_URL_STATIC_DIRECTORY}/static/images/tabs-panels/how-to-sell-my-home.svg`,
        },
        {
          id: 'homepage-property-tools-instant-valuation',
          cardTitle: 'What is my home worth?',
          cardText: 'Get an instant online valuation',
          url: `${BASE_URL}/instant-valuation`,
          image: `${CDN_URL_STATIC_DIRECTORY}/static/images/tabs-panels/home-value.svg`,
          opensInSameTab: true,
        },
        {
          id: 'homepage-property-tools-agent-valuation',
          cardTitle: 'Get a free expert home valuation',
          cardText: 'Get a no-obligation valuation from your local agent',
          url: `${BASE_URL}/request-valuation`,
          image: `${CDN_URL_STATIC_DIRECTORY}/static/images/tabs-panels/get-free-agent-valuation.svg`,
          opensInSameTab: true,
        },
        {
          id: 'homepage-property-tools-Advantage-Ad',
          cardTitle: 'Market your home with an Advantage Ad',
          cardText: 'Unbeatable Visibility, more offers, best price',
          url: 'https://www.advantage.daft.ie/?utm_source=Home+Page&utm_medium=Property+Tools+Selling+Tab&utm_campaign=Advantage+Ads',
          image: `${CDN_URL_STATIC_DIRECTORY}/static/images/tabs-panels/market-your-home.svg`,
        },
        {
          id: 'homepage-property-tools-enable-offers',
          cardTitle: 'Enable online offers',
          cardText: 'See offers in real time on your home',
          url: 'https://www.sell.daft.ie/offers/?utm_source=Home+Page&utm_medium=Property+Tools+Selling+Tab&utm_campaign=Enable+Offers',
          image: `${CDN_URL_STATIC_DIRECTORY}/static/images/tabs-panels/enable-online-offers.svg`,
        },
        {
          id: 'homepage-property-tools-ad-approval',
          cardTitle: 'Approve my ad',
          cardText: 'Preview and approve your ad before going live',
          url: 'https://www.sell.daft.ie/#vendor-ad-approval',
          image: `${CDN_URL_STATIC_DIRECTORY}/static/images/tabs-panels/approve-my-ad.svg`,
        },
      ],
    },
  },
  {
    section: 'Buying',
    content: {
      list: [
        {
          id: 'homepage-property-tools-BB',
          cardTitle: 'Calculate your Buying Budget',
          cardText: 'Find which home you can afford',
          url: 'https://www.daft.ie/daft-mortgages/buying-budget',
          image: `${CDN_URL_STATIC_DIRECTORY}/static/images/tabs-panels/calculate-your-budget.svg`,
          opensInSameTab: true,
        },
        {
          id: 'homepage-property-tools-offers',
          cardTitle: 'Search properties with online offers',
          cardText: 'Place and track offers on homes in real-time',
          url: 'https://www.daft.ie/property-for-sale/ireland?offersEnabledDisabled=true',
          image: `${CDN_URL_STATIC_DIRECTORY}/static/images/tabs-panels/search-properties.svg`,
          opensInSameTab: true,
        },
      ],
    },
  },
];
