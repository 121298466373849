import React, { useContext, useEffect } from 'react';

import { NextSeo } from 'next-seo';

import HomePage from '../components/HomePage/HomePage';
import { PropertyToolsConfig } from '../components/HomePage/homepage-components/PropertyTools.config';
import { HomepageSEOConfig, ResidentialSearchHeroSections } from '../config';
import { PopularAreasList, SEOContent } from '../config/popularAreas';
import { UserDetailsContext } from '../contexts/userDetailsContext';
import { generateRecaptchaToken } from '../helpers/reCaptcha/reCaptcha';
import {
  BASE_URL,
  CDN_URL_STATIC_DIRECTORY,
  ENVIRONMENT_NAME,
  SITE_NAME,
} from '../helpers/utils';
import { GoogleAnalyticsService } from '../services/Analytics/AnalyticsService';

import { AreaTag } from '../types';

type HomepageProps = {
  locationTags: AreaTag[];
  shouldIndexPage: boolean;
  path: string;
  daftCookies: { [key: string]: string };
  reactDFPAdsReadyToLoad: boolean;
};

const Index = ({
  daftCookies,
  path,
  reactDFPAdsReadyToLoad,
}: HomepageProps) => {
  const userDetails = useContext(UserDetailsContext);

  // remove queries from path
  path = path.split('?')[0].replace('#', '');

  useEffect(() => {
    const analyticsService: GoogleAnalyticsService =
      new GoogleAnalyticsService();

    try {
      // send user details as wootric properties
      analyticsService.pushToDataLayer(userDetails);
    } catch (error) {
      console.warn(error);
    }
  }, [userDetails]);

  // generate recaptcha token for data tracking
  useEffect(() => {
    generateRecaptchaToken('home_page');
  }, []);

  const {
    metaTitle = 'Daft.ie',
    metaDescription = '',
    tagline = '',
    ogImage = `${CDN_URL_STATIC_DIRECTORY}/meta-default.jpg`,
  } = HomepageSEOConfig[path];

  const openGraphMeta = {
    type: 'website',
    locale: 'en_IE',
    url: `${BASE_URL}${path}`,
    title: metaTitle,
    description: metaDescription,
    images: [
      {
        url: ogImage,
        width: 1164,
        height: 699,
        alt: 'Daft.ie Hero',
      },
    ],
    site_name: `${SITE_NAME}`,
  };

  return (
    <>
      <NextSeo
        title={metaTitle}
        canonical={`${BASE_URL}${path}`}
        description={metaDescription}
        openGraph={{ ...openGraphMeta }}
      />

      <HomePage
        tagline={tagline}
        searchHeroSections={ResidentialSearchHeroSections}
        popularAreas={PopularAreasList}
        currentPath={path}
        category="RESIDENTIAL"
        theme="ALTERNATIVE"
        backgroundImage={HomepageSEOConfig[path].backgroundImage}
        daftCookies={daftCookies}
        reactDFPAdsReadyToLoad={reactDFPAdsReadyToLoad}
        SEOContent={SEOContent}
        propertyTools={PropertyToolsConfig}
      />
    </>
  );
};

export default Index;

Index.getInitialProps = async () => {
  const shouldIndexPage = ENVIRONMENT_NAME === 'production';

  return {
    shouldIndexPage,
  };
};
